// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react'

const theme = {
    fonts: {
        body: `"Cutive Mono", monospace`,
        heading: `"Cutive Mono", monospace`,
    }
}

export default extendTheme(theme)